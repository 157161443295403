import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "frequently-asked-questions"
    }}>{`Frequently Asked Questions`}</h1>
    <hr></hr>
    <p>{`The Frequently Asked Questions (FAQ) section is designed to address common queries and provide helpful answers to commonly encountered concerns. This section serves as a valuable resource for seeking quick solutions and clarification on various aspects of the topic or service. Browse through the following list of frequently asked questions to find answers to your inquiries and gain a deeper understanding of the subject matter. If your specific question is not listed, feel free to reach out for further assistance.`}</p>
    <ul>
      <li parentName="ul">{`How do I receive updates for the latest version of the app?`}
        <ul parentName="li">
          <li parentName="ul">{`Your apps, both in the Apple App Store and the Google Play Store, will update automatically for you when a new version is released. There is no downtime or action required for you or your patrons.  `}</li>
        </ul>
      </li>
      <li parentName="ul">{`How many users can I create within the Content Management System?`}
        <ul parentName="li">
          <li parentName="ul">{`There is no limit on how many users you can create. `}</li>
        </ul>
      </li>
      <li parentName="ul">{`How will my app appear in the app stores?`}
        <ul parentName="li">
          <li parentName="ul">{`Your app will be branded as your library in both stores. You will get to decide what the app is called which will be how it will be searched in both stores for download. `}</li>
        </ul>
      </li>
      <li parentName="ul">{`How many languages does the app currently support?`}
        <ul parentName="li">
          <li parentName="ul">{`The app can be translated into 32 different languages. To learn more about to configure this, and what languages are offered, please refer to the `}<a parentName="li" {...{
              "href": "https://appdocs.sol.us/edit-languages"
            }}>{`Edit Languages`}</a>{` page for more information. `}</li>
        </ul>
      </li>
      <li parentName="ul">{`Does the app support light and dark modes?`}
        <ul parentName="li">
          <li parentName="ul">{`We currently support this on Apple devices.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`For self-check out, is it limited to that library or could a patron use it at any shared system library?`}
        <ul parentName="li">
          <li parentName="ul">{`The self-check out option is setup by location. Only patrons at that library would be able to use the self-check option with either barcode or RFID.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Will patrons' OverDrive, Hoopla, and Kanopy checkouts display in the My Account section within the app?`}
        <ul parentName="li">
          <li parentName="ul">{`Hoopla and OverDrive checkouts will display within the My Account display. Kanopy does not currently have an API connector at present. Kanopy was recently purchased by OverDrive, so it's likely their separate API endpoints will be made available at some point. `}</li>
        </ul>
      </li>
      <li parentName="ul">{`When linking accounts, can a hold be placed by a parent for a child? `}
        <ul parentName="li">
          <li parentName="ul">{`Yes. `}</li>
        </ul>
      </li>
      <li parentName="ul">{`Why do patrons' need to log into the app before being allowed to perform a search?`}
        <ul parentName="li">
          <li parentName="ul">{`This was by design with the 2023.1 update to increase our backend security especially after the attempted DDoS attacks we experienced at the start of this year. This reduces hackers' ability to spam an app and make unnecessary API calls to the ILS. Issues were amplified during the attempted attacks as it was adding additional load on the ILS severs to the excessive API calls that were being made. In the new version, any request that requires an ILS API call requires the patron to sign in first.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Why is my app rated 17+ in the Apple App Store?`}
        <ul parentName="li">
          <li parentName="ul">{`Your app is marked as 17+ because it can link our to external services like YouTube. To learn more about how Apple defines the age rating, please refer to their `}<a parentName="li" {...{
              "href": "https://developer.apple.com/help/app-store-connect/reference/age-ratings/"
            }}>{`Age rating`}</a>{` page for more information. `}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      